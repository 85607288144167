.header {
  padding: 25px 70px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.04);
}

.boxLeft {
  align-items: center;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
}

.icon {
  align-items: center;
  height: 22px;
}

.menu {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 14px;
  padding-right: 14px;
}

.menuText {
  text-decoration: none;
  color: black;
  font-size: 18px;
}

.menuText:hover {
  font-size: 20px;
}

.active p {
  font-weight: 500;
}

@media (max-width: 770px) {
  .header {
    height: 180px;
    padding: 15px 30px;
  }

  .boxLeft {
    flex-direction: column;
    align-items: flex-start;
  }

  .menuText {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
  }
}
