.footer {
  box-shadow: 0px -5px 9px rgba(0, 0, 0, 0.04);
  max-width: 100%;
}

.text {
  padding-left: 50px;
  margin: 0;
  padding: 0;
  margin: 25px;
}

.contactpageContacts {
  margin-top: 25px;
  margin-right: 25px;
  text-decoration: none;
  color: black;
}

.contactpageContacts:hover {
  color: blueviolet;
}

.footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contactBox {
  display: flex;
  flex-direction: row;
}

@media (max-width: 770px) {
  .contactBox {
    flex-direction: column;
  }
}
