.main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.portfolio {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.portfolioImages {
  display: grid;
  /* grid-template-columns: repeat(3, 1fr); */
  gap: 25px;
  object-fit: contain;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 25px;
}

.hoverOpacity:hover {
  opacity: 0.6;
}

.portfolioImage {
  display: flex;
  flex-direction: column;
}

.portfolioImage img {
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.portfolioTitle {
  text-decoration: none;
  color: black;
}

.nameRoom {
  padding-top: 40px;
  text-align: center;
}

@media (max-width: 1300px) {
  .portfolioImages {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 770px) {
  .portfolioImages {
    grid-template-columns: repeat(1, 1fr);
  }

  .girlPhotos {
    flex-direction: column;
    align-items: center;
  }
  .girlPhoto {
    margin-bottom: 25px;
  }
}
