.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.aboutusMain {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.aboutus {
  padding: 30px;
  margin: auto;
}

.aboutusImg {
  display: flex;
  max-width: 500px;
  max-height: 400px;
  object-fit: contain;
  width: 100%;
  height: 100%;
  float: left;
  margin: 0 20px 20px 0;
}

.aboutusImg img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.aboutusText {
  padding-left: 25px;
  max-width: 1000px;
}

.aboutusTextCenter {
  text-align: center;
  padding-bottom: 30px;
}

.girlPhotos {
  padding: 30px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 1000px;
  margin: auto;
}

.girlPhoto {
  max-width: 200px;
  padding: 0 45px;
}

.girlPhoto img {
  width: 170px;
  height: auto;
}

.girlName {
  margin: 0;
  padding: 0;
}

.girlProf {
  margin: 0;
  padding: 0;
  font-size: small;
}

.pricePage {
  margin-bottom: 50px;
}

.pricePageTitle {
  margin: 0;
  margin-bottom: 50px;
  margin-top: 70px;
  color: blueviolet;
}

.pricePageStepBox {
  display: flex;
  position: relative;
  margin-bottom: 50px;
}

.pricePageNumber {
  margin: 0;
  position: absolute;
  font-size: 100px;
  margin: 0;
  color: rgb(214, 211, 211);
  z-index: -1;
  top: 50%;
  left: 5%;
  transform: translate(-50%, -50%);
  font-weight: 700;
}

.pricePageStepBoxDescription {
  width: 200px;
  margin: 0;
}

.pricePageStepBoxUl {
  margin: 0;
}

.pricePagePriceBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-weight: 600;
}

.pricePagePriceBox p {
  margin: 0;
  padding: 0;
  margin-right: 2px;
  padding: 2px;
}

.pricePagePriceBoxAmmount {
  color: blueviolet;
  font-weight: 600;
}

.pricePageStepBoxPomesheniya {
  justify-content: space-between;
  margin-bottom: 25px;
}

.pricePagePriceBox2 {
  justify-content: none;
}

.leftside {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.lineUp {
  margin-top: 40px;
  margin-bottom: 40px;
  border-top: 1px solid rgb(202, 202, 202);
}

.contactpage {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
  max-width: 300px;
  min-height: 60vh;
}

.contactpageLocation {
  padding-bottom: 40px;
  padding-top: 20px;
  font-size: 20px;
}

.contactpageContacts {
  text-decoration: none;
  color: black;
}

.contactpageContacts:hover {
  color: blueviolet;
}

.portfolio {
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
}

.portfolioImages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.hoverOpacity:hover {
  opacity: 0.6;
}

.portfolioImage {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.portfolioImage img {
  max-width: 100%;
  max-height: 400px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.portfolioTitle {
  text-decoration: none;
  color: black;
}

.pageFourOFour {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 150px 20px 150px 20px;
  min-height: 40vh;
}

.pageFourOFour p {
  font-weight: 500px;
  font-size: 30px;
}

@media (max-width: 1300px) {
  .portfolioImages {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 770px) {
  .aboutusImg {
    max-width: 300px;
    max-height: 200px;
  }

  .pricePageStepBoxDescription {
    font-size: 12px;
    width: 100px;
    align-self: center;
    margin: auto 0;
  }

  .pricePageNumber {
    font-size: 80px;
  }

  .portfolioImages {
    grid-template-columns: repeat(1, 1fr);
  }

  .girlPhotos {
    flex-direction: column;
    align-items: center;
  }
  .girlPhoto {
    margin-bottom: 25px;
  }
}
