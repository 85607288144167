.sliderContainer {
  width: 90%;
  margin: 0 auto;
}

.slickSlider {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  max-height: 500px;
  text-align: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.slickSlide {
  max-height: 700px;
}

.slickDots {
  bottom: 10px;
}

@media (max-width: 770px) {
  .slickSlide {
    max-height: 400px;
  }
}

@media (max-width: 600px) {
  .slickSlide {
    max-height: 300px;
  }
}

@media (max-width: 500px) {
  .slickSlide {
    max-height: 200px;
  }
}
