.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.aboutusText {
  max-width: 1500px;
  padding: 25px;
}

.mainImg img {
  max-width: 300px;
  object-fit: contain;
}

.boxWithImage {
  display: flex;
  padding: 30px;
}
